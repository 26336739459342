<template>
  <div class="algorithm-container">
    <div class="algorithm-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="算法名称">
          <el-input
            v-model="formData.sn"
            placeholder="请输入算法名称"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="生效状态">
          <el-select
            v-model="formData.level"
            placeholder="信息等级"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="生效" :value="0"></el-option>
            <el-option label="不生效" :value="1"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
        <!-- <el-row>
          <el-form-item>
            <el-button type="primary" @click="addHandle()">新增</el-button>
          </el-form-item>
        </el-row> -->
      </el-form>
    </div>
    <div class="algorithm-table-container">
      <div class="algorithm-table-content">
        <el-table
          style="width: 100%"
          height="100%"
          :data="list"
          v-loading="loading"
        >
          <el-table-column label="算法名称" prop="sn">
            <template slot-scope="scope">
              {{scope.row.name || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="预警等级" prop="producer">
            <template slot-scope="scope">
              {{scope.row.level || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="生效状态" >
            <template slot-scope="scope">
              <span style="color: #008000" v-if="scope.row.status == 1">生效</span>
						<span style="color: #ff0000" v-else>不生效</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button @click="jumpDetailPage(scope.row, 1)" type="text" size="mini"
                >基础配置</el-button
              >
              <el-button @click="jumpDetailPage(scope.row, 2)" type="text" size="mini"
                >参数配置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="algorithm-table-page">
        <div class="algorithm-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import "./Index.less";
export default {
  name: "AlgorithmManagement",
  data() {
    return {
      list: [
        {name: '安全算法', level: '三级', status: 1, id: '13233sadsa'},
        {name: '一致性算法', level: '三级', status: 2, id: '13233sadsffa'},
        {name: '状态管理算法', level: '二级', status: 1, id: '1sdas3233sadsa'},
        {name: '用户行为影响分析算法', level: '一级', status: 2, id: '132sdsa33sadsa'},
        {name: '续驶里程预估算法', level: '三级', status: 1, id: '13233sadasadsadaa'},
        
      ],
      total: 5,
      loading: false,
      visable: false,
      sourceList: [],
      formData: {
        sn: null,
        level: null,
        status: null,
        source: null,
        startTime: null,
        endTime: null,
        time: null,
        firstPerson: null,
        lastPerson: null,
        organId: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  components: {  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    // this.getData();
    // this.getSourceList()
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/customerServiceCenter/query`, this.formData)
        .then((res) => {
          const { list, total, page } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        level: null,
        status: null,
        source: null,
        startTime: null,
        endTime: null,
        time: null,
        firstPerson: null,
        lastPerson: null,
        organId: localStorage.getItem("organId"),
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
      };
      this.getData();
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/source`)
        .then((res) => {
          this.sourceList = res
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.formData.startTime = this.DateToStr(data[0]);
        this.formData.endTime = this.DateToStr(data[1]);
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    addHandle(row) {
      this.$router.push({
        name: "AddCustomer",
        params: {
          
        },
      });
    },
    jumpDetailPage(row, type) {
      this.$router.push({
        name: "AddCustomer",
        params: {
          id: row.id,
          type: type
        },
      });
    },
    setHandle(item) {
      this.visable = true;
      let childProblem = item.childProblemTextList||[].toString()
      this.$nextTick(() => {
        this.$refs.setModel.init();
        this.$refs.setModel.title = item.code + " 工单处理";
        this.$refs.setModel.dataForm.sn = item.sn;
        this.$refs.setModel.dataForm.questionTypeText = item.questionTypeText;
        this.$refs.setModel.dataForm.childProblemTextList = childProblem
        this.$refs.setModel.dataForm.resultText = item.resultText
        this.$refs.setModel.dataForm.sourceId = item.id
      });
    }
  },
};
</script>